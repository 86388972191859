import React from "react";
import "./App.css";
import "materialize-css";
import {
  Button,
  Card,
  Row,
  Col,
  CardTitle,
  Slider,
  Slide,
  Caption,
  Footer,
  Modal,
} from "react-materialize";
import Home from "./components/Home.jsx";
import Nr1 from "./components/nr1.jsx";
import Nr2 from "./components/nr2.jsx";
import Nr3 from "./components/nr3.jsx";
import Contact from "./components/Contact";
import bg1 from "./components/svg/bg1.webp";
import DocumentMeta from "react-document-meta";

function App() {
  const meta = {
    title: "Hydraulik Wnek",
    description: "Hudraulik w Krakowie",
    canonical: "https://hydraulikwnek.com/",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Hudraulik, wnek, awaria, hydraulika, hydraulik, instalacje,instalacje gazowe, instalacje c.o., instalacje wod-kan",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
    <div className="App">
      <div className="">
        <Home />
      </div>
      {/* <div className="hide-on-small-only">
      <Row/><Row/>
      </div> */}
      <Slider
        className="z-depth-3 Slider"
        fullscreen={false}
        options={{
          duration: 500,
          height: 800,
          indicators: false,
          interval: 9000,
        }}
      >
        <Slide image={<img alt="" width="100%" src={bg1} />}>
          <Caption className="" placement="center">
            <Card
              className="blue darken-3 bold"            >
              <div className="h3">Doświadczony hydraulik w Krakowie</div>
              <a
            className="btn-large blue darken-3 z-depth-5 bold "
            href="tel:+48789807598"
          >
            <h6> tel: 789 807 598 </h6>
          </a>
              <Row />
              <h5>
                <div className="bold">
                  Działam od 1996 roku, w Krakowie i okolicach
                </div>{" "}
                <Row />
                Instalacje wod-kan, c.o., gazowe - szeroki zakres robót
                <Row />
                Szybko i profesjonalnie, szerokie uprawnienia - od budowlanych
                po energetyczne
              </h5>
              Więcej informacji poniżej
            </Card>
          </Caption>
        </Slide>
        <Slide
          image={
            <img
              alt=""
              width="100%"
              src="https://images.unsplash.com/photo-1517646287270-a5a9ca602e5c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
            />
          }
        >
          <Caption placement="center">
            <Card className="blue darken-3 ">
              <h3 className="white-text bold text-darken-2">
                Instalacje wod-kan
              </h3>
              <a
            className="btn-large blue darken-3 z-depth-5 bold "
            href="tel:+48789807598"
          >
            <h6> tel: 789 807 598 </h6>
          </a>
              <h5 className="white-text  text-darken-2">
                Kompleskowe wykonywanie instalacji, przyłączy
                <Row />
                Udrażnianie instalacji
                <Row />
                Naprawy wycieków
                <Row />
              </h5>{" "}
              Więcej informacji poniżej
            </Card>
          </Caption>
        </Slide>
        <Slide
          image={
            <img
              alt=""
              width="100%"
              src="https://images.unsplash.com/photo-1599028274511-e02a767949a3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
            />
          }
        >
          <Caption placement="center">
            <Card className="red">
              <h3 className="bold">Instalacje c.o.</h3>
              <a
            className="btn-large red darken-3 z-depth-5 bold "
            href="tel:+48789807598"
          >
            <h6> tel: 789 807 598 </h6>
          </a>
              <h5 className="white-text  text-darken-2">
                Wykonywanie kompleksowej instalacji c.o.
                <Row />
                Instalacje podłogowe
                <Row />
                Montaż kotłów gazowych
              </h5>{" "}
              Więcej informacji poniżej
            </Card>
          </Caption>
        </Slide>
        <Slide
          image={
            <img
              alt=""
              src="https://images.unsplash.com/photo-1608454770647-01dc0f7dd97d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
            />
          }
        >
          <Caption placement="center">
            <Card className="orange darken-3">
              <h3 className="bold">Instalacje gazowe</h3>
              <a
            className="btn-large orange darken-3 z-depth-5 bold "
            href="tel:+48789807598"
          >
            <h6> tel: 789 807 598 </h6>
          </a>
              <h5 className="white-text  text-darken-2">
                Kompleksowe wykonywanie instalacji gazowych
                <Row />
                Naprawy i zmiany instalacji
                <Row />
              </h5>{" "}
              Więcej informacji poniżej
            </Card>
          </Caption>
        </Slide>
      </Slider>
      <Row />
      <a
        className="btn-large red awaria right-align bold z-depth-5 hide-on-small-only"
        href="tel:+48789807598"
      >
        AWARIA? ZADZWOŃ! tel:+48 789 807 598
      </a>

      <div className="hide-on-med-and-up">
        <div className=" center-align">
          <a
            className="btn-large blue darken-3 z-depth-5 bold "
            href="tel:+48789807598"
          >
            <h5> tel: 789 807 598 </h5>
          </a>

          <a
            className="btn-large blue darken-3 z-depth-5 bold"
            href="mailto:wnekhydraulika@gmail.com"
          >
            <h5>wnekhydraulika@gmail.com</h5>
          </a>

          <Button
            className="z-depth-5 blue darken-3 "
            large
            target="blank"
            node="a"
            href="https://goo.gl/maps/uE3cF5my8HNvK7kTA"
          >
            <h5>⚑ adres</h5>
          </Button>
          <Button
            className="z-depth-5 blue darken-3 "
            large
            target="blank"
            node="a"
            href="https://www.facebook.com/Pphpu-El-Gaz-Wod-Trans-100306922667762"
          >
            <h5>FB</h5>
          </Button>
          <Button
            className="z-depth-5 green darken-1 "
            large
            target="blank"
            node="a"
            href="https://api.whatsapp.com/send?phone=+48789807598"
          >
            ✆ WHATSAPP
          </Button>
          <br/>
          <a
            className="btn-large red right-align bold z-depth-5"
            href="tel:+48789807598"
          >
            AWARIA? ZADZWOŃ!
          </a>
        </div>
      </div>

      <Row>
        <Col s={12} m={10} l={10} push="m1 l1">
          <Row />
          <Row />
          <Row />
          <Row />
          <Row />
          <Nr1 />
          <Row />
          <Row />
          <Nr2 />
          <Row />
          <Row />
          <Nr3 />
        </Col>
      </Row>
      <Contact />
      <Footer
        className="blue"
        copyrights="Copyright Choluj Design S.C. "
        links={
          <ul>
            <Modal
              actions={[
                <Button flat modal="close" node="button" waves="green">
                  Close
                </Button>,
              ]}
              bottomSheet={false}
              fixedFooter={false}
              header="RODO"
              id="Modal-10"
              open={false}
              options={{
                dismissible: true,
                endingTop: "10%",
                inDuration: 250,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                opacity: 0.5,
                outDuration: 250,
                preventScrolling: true,
                startingTop: "4%",
              }}
              trigger={
                <Button className="blue" node="button">
                  RODO
                </Button>
              }
            >
              <p>
                RODO Klauzula informacyjna dla klientów i kontrahentów. Zgodnie
                z przepisami rozporządzenia Parlamentu Europejskiego i Rady (UE)
                2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
                fizycznych w związku z przetwarzaniem danych osobowych i w
                sprawie swobodnego przepływu takich danych oraz uchylenia
                dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych)
                („RODO”), przekazujemy poniższe informacje dotyczące Państwa
                danych osobowych. Administrator danych osobowych Administratorem
                Państwa danych osobowych jest P.P.H.U. EL-GAZ-WOD-TRANZ Cel i
                podstawa przetwarzania Państwa dane osobowe przetwarzamy: • w
                celu zgodnym z udzieloną zgodą, o ile została wyrażona zgoda, na
                podstawie art. 6 ust. 1 lit. a RODO; • w celu udzielenia
                odpowiedzi w związku z Państwa zainteresowaniem naszą ofertą
                handlową, zgodnie z art. 6 ust. 1 lit. b RODO; • w celu zawarcia
                i wykonania umowy, na podstawie art. 6 ust. 1 lit. b RODO; • w
                celu realizacji obowiązków prawnych, na przykład wynikających z
                przepisów podatkowych, na podstawie art. 6 ust. 1 lit. c RODO; •
                w celu wykonywania naszych prawnie uzasadnionych interesów jako
                Administratora, o których mowa w art. 6 ust. 1 lit. f RODO,
                takich jak marketing bezpośredni, dochodzenie lub obrona przed
                roszczeniami. Odbiorcy danych osobowych Odbiorcami Państwa
                danych będą następujące podmioty: • podmioty, którym
                powierzyliśmy przetwarzanie danych osobowych w naszym imieniu:
                spółki informatyczne, kancelarie prawne, jednostki audytujące,
                agencje ochrony; • podmioty lub organy uprawnione na podstawie
                przepisów prawa. Okres przechowywania danych Państwa dane
                osobowe będą przechowywane: • w przypadku przetwarzania danych
                na podstawie zgody – do czasu cofnięcia zgody; • w przypadku
                przetwarzania danych na podstawie w celu przedstawienia oferty –
                przez okres negocjowania umowy oraz do końca roku kalendarzowego
                następującego po roku, w którym ostatni raz kontaktowali się
                Państwo w sprawie jej zawarcia; • w przypadku przetwarzania
                danych na podstawie w celu wykonania umowy – do momentu
                wygaśnięcia umowy; • w przypadku przetwarzania w celu realizacji
                obowiązków prawnych - przez okres w jaki aktualne przepisy prawa
                nakazują ich przechowywanie; • w przypadku przetwarzania danych
                na podstawie uzasadnionego interesu Administratora – do momentu
                ustania tego interesu (np. przedawnienia roszczeń) lub do
                momentu wniesienia skutecznego sprzeciwu, przeciwko dalszemu
                przetwarzaniu przez osobę, której dane dotyczą. Prawa osób,
                których dane dotyczą Zgodnie z RODO przysługuje Państwu: • prawo
                dostępu do swoich danych oraz otrzymania ich kopii; • prawo do
                sprostowania (poprawienia) swoich danych; • prawo do usunięcia
                danych; • prawo do ograniczenia przewarzania danych; • prawo do
                sprzeciwu; • prawo do wniesienia skargi do Prezesa Urzędu
                Ochrony Danych Osobowych. Informacja na temat prawa cofnięcia
                zgody W stosunku do danych przetwarzanych na podstawie zgody
                informujemy, że wyrażenie zgody każdorazowo jest całkowicie
                dobrowolne, a w przypadku jej wyrażenia w dowolnym momencie
                przysługuje Państwu prawo do jej wycofania. Cofnięcie zgody nie
                będzie wpływać na zgodność z prawem przetwarzania, którego
                dokonano dotychczas. Obowiązek podawania danych osobowych
                Podanie danych jest dobrowolne, niemniej jednak konieczne, jeśli
                chcą Państwo zawrzeć z nami umowę lub otrzymać oferty. Podanie
                danych na postawie zgody jest całkowicie dobrowolne. Informacja
                na temat zautomatyzowanego podejmowania decyzji i profilowania W
                związku z przetwarzaniem Państwa danych osobowych nie dochodzi
                do zautomatyzowanego podejmowania decyzji, w tym do
                profilowaniu. English In accordance with the provisions of the
                Regulation of the European Parliament and of the Council (EU)
                2016/679 of 27 April 2016 on the protection of natural persons
                in connection with the processing of personal data and on the
                free movement of such data data and repealing Directive 95/46 /
                EC (general protection regulation data) ("GDPR"), we provide the
                following information regarding your data personal. Personal
                data administrator The administrator of your personal data is
                P.P.H.U. EL-GAZ-WOD-TRANS. Purpose and basis of processing We
                process your personal data: • for the purpose consistent with
                the consent granted, provided that consent has been given,
                pursuant to Art. 6 sec. 1 lit. a GDPR; • in order to respond to
                your interest in our offer commercial, in accordance with Art. 6
                sec. 1 lit. b GDPR; • in order to conclude and perform a
                contract, pursuant to Art. 6 sec. 1 lit. b GDPR; • in order to
                comply with legal obligations, such as those resulting from
                regulations tax, pursuant to Art. 6 sec. 1 lit. c GDPR; • in
                order to pursue our legitimate interests as The administrator
                referred to in art. 6 sec. 1 lit. f GDPR, such as marketing
                direct, investigation or defense against claims. Recipients of
                personal data The recipients of your data will be the following
                entities: • entities entrusted with the processing of personal
                data in our on behalf of: IT companies, law firms, audit units,
                agencies protection; • entities or bodies authorized by law. The
                period of data storage your personal data will be stored: • in
                the case of data processing on the basis of consent - until the
                consent is withdrawn; • in the case of data processing on the
                basis of the purpose of presenting an offer - for the period of
                contract negotiation and until the end of the calendar year
                following the year in which you last contacted about the matter
                its conclusion; • in the case of data processing on the basis of
                the performance of a contract - up to the moment the contract
                expires; • in the case of processing in order to fulfill legal
                obligations - for the period of what current legal regulations
                require their storage; • in the case of data processing based on
                a legitimate interest Administrator - until the end of this
                interest (e.g. limitation of claims) or until an effective
                objection is raised, against another processing by the data
                subject. Rights of data subjects According to the GDPR, you are
                entitled to: • the right to access your data and receive a copy
                of it; • the right to rectify (correct) your data; • the right
                to delete data; • the right to limit data processing; • right to
                object; • the right to lodge a complaint with the President of
                the Personal Data Protection Office. Information on the right to
                withdraw consent In relation to data processed on the basis of
                consent, we inform that consent is each time entirely voluntary,
                and in the case of it expression, you have the right to withdraw
                it at any time. Withdrawal of consent will not affect the
                lawfulness of the processing has been done so far. Obligation to
                provide personal data Providing data is voluntary, but necessary
                if you want to enter into a contract with us or receive offers.
                Providing data on the basis of consent is completely voluntary.
                Information on automated decision making and profiling In
                connection with the processing of your personal data, there is
                no automated decision making, including profiling.
              </p>
            </Modal>
          </ul>
        }
        moreLinks={
          <Button
            className="z-depth-5 blue darken-3 "
            large
            target="blank"
            node="a"
            href="https://cholujdesign.com"
          >
            CHD
          </Button>
        }
      >
        <h5 className="white-text">Hydraulik Bogusław Wnęk</h5>
        <p className="grey-text text-lighten-4">
          Doświadczony hydraulik Kraków
        </p>
      </Footer>
    </div>
    </DocumentMeta>
  );
}

export default App;
